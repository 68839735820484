import React from "react";
import { DashboardHeader } from "../../components/Header";
import { Accounts } from "../../components/Accounts";
import language from "../../language";
import { connect } from "react-redux";
import { Tabs } from "../../components/Tabs";
import { defineRole } from "../../utils/handleAccessRoles";
import { getStorage } from "../../utils/storage";
import { AccessRoles } from "../../components/AccessRole";

class AccountScreen extends React.Component {
  state = {
    user: {},
  };

  componentDidMount = async () => {
    const user = await getStorage();

    this.setState({ user });
  };

  returnTabsOptions = () => {
    const { user } = this.state;
    const { accessRole } = user;

    const canReadActive = defineRole({
      roles: accessRole,
      menu: "Active Accounts",
      operation: "read",
    });

    const canReadInactive = defineRole({
      roles: accessRole,
      menu: "Inactive Accounts",
      operation: "read",
    });

    const canReadOutsidersAccount = defineRole({
      roles: accessRole,
      menu: "Outside Organization Accounts",
      operation: "read",
    });

    const canReadAccess = defineRole({
      roles: accessRole,
      menu: "Access Roles",
      operation: "read",
    });

    let tabsOptions = [];

    if (canReadActive) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].organization_accounts,
        data: <Accounts active outsideOrganization={{ $ne: true }} />,
      });
    }

    if (canReadOutsidersAccount) {
      tabsOptions.push({
        title:
          language[this.props.defaultLanguage].outside_organization_accounts,
        data: <Accounts active outsideOrganization={{ $ne: false }} />,
      });
    }

    if (canReadInactive) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].inactive_accounts,
        data: <Accounts active={false} />,
      });
    }

    if (canReadAccess) {
      tabsOptions.push({
        title: language[this.props.defaultLanguage].access_roles,
        data: <AccessRoles />,
      });
    }

    return tabsOptions;
  };



  render() {
    const { accounts_title, accounts_description } =
      language[this.props.defaultLanguage];
    return (
      <>
        <DashboardHeader
          title={accounts_title}
          description={accounts_description}
        />
        <div className="card card-dashboard-content">
          <div className="card-body">
            <Tabs options={this.returnTabsOptions()} />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { defaultLanguage } = state.Language;
  return {
    defaultLanguage,
  };
};

export default connect(mapStateToProps)(AccountScreen);
