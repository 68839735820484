import toastMessage from "../toastMessage";
import { getStorage } from "../storage";
import axios from "axios";
import language from "../../language";
import { defineRole } from "../handleAccessRoles";

const API_URL = process.env.REACT_APP_PSRP_BASE_API;

const fetchData = async (lng = "english", params = {}) => {
  let {
    page,
    limit,
    active = undefined,
    outsideOrganization = false,
    id,
  } = params;
  try {
    const user = await getStorage();

    const { accessRole } = user;

    const canRead = defineRole({
      roles: accessRole,
      menu: active === false ? "Inactive Accounts" : "Active Accounts",
      operation: "read",
    });

    if (!canRead) return;

    let body = {
      page: page,
      limit: limit,
      active,
      institution: user.institution,
      outsideOrganization,
      id,
      accessRole: params.accessRole,
    };

    if (user.role === "superAdmin") {
      delete body.institution;
    }

    const options = {
      method: "GET",
      url: `${API_URL}/account`,
      params:body,
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    const queryRes = await axios(options);

    let { data, count } = queryRes.data;

    if (!data) {
      data = queryRes.data;
    }

    const result = data.map((el) => {
      return {
        ...el,
        label: el.firstName + " " + el.lastName,
        value: el._id,
      };
    });

    return {
      data: result,
      count,
    };
  } catch (error) {
    toastMessage("error", language[lng].unable_fetch_accounts);
  }
};

export default fetchData;

export const deleteAccount = async (lng = "english", id) => {
  try {
    const user = await getStorage();

    const options = {
      method: "DELETE",
      url: `${API_URL}/account`,
      data: {
        id,
      },
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    };

    await axios(options);

    toastMessage("success", language[lng].success_delete_account);
  } catch (error) {
    toastMessage("error", error);
  }
};
